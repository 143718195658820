<template>
  <div>
    <div class="topbasic">
      
      <div class="tbtitle">基本信息</div>
      <div class="tbcont">
        <div class="account">
          <el-form
            ref="form"
            :label-position="labelPosition"
            label-width="120px"
            :model="formLabelAlign"
          >
            <el-form-item label="账号：">
              <span>{{ user.username }}</span>
            </el-form-item>
          </el-form>
        </div>
        <div class="account">
          <el-form
            ref="form"
            :label-position="labelPosition"
            label-width="120px"
            :model="formLabelAlign"
          >
            <el-form-item label="用户名：">
              <div v-if="!edit_contact">
                <span>{{ user.name }}</span>
                <i class="el-icon-edit" @click="edit_contact = true"></i>
              </div>
              <div v-else class="changePx">
                <el-input v-model="user.name" />
                <i class="el-icon-check" @click="editSelf()"></i>
              </div>
            </el-form-item>
          </el-form>
        </div>

        <div class="account" style="margin-left: 200px">
          <el-form
            ref="form"
            :label-position="labelPosition"
            label-width="120px"
            :model="formLabelAlign"
          >
            <el-form-item label="重置密码：">
              <div v-if="!edit_pwd">
                <span>********</span>
                <i class="el-icon-edit" @click="edit_pwd = true"></i>
              </div>
              <div v-else class="changePx">
                <div>
                  <el-input v-model="oldPassword" placeholder="请输入原密码" />
                  <span style="color: red" v-if="showPass">请输入原密码</span>
                </div>
                <div>
                  <el-input v-model="newPassword" placeholder="请输入新密码" />
                  <span style="color: red" v-if="showNewPass"
                    >请输入新密码</span
                  >
                </div>
                <div>
                  <i class="el-icon-check" @click="editSelf()"></i>
                </div>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <div class="testtable">
      <div class="tttotle">
        <div class="ttleft">地级市账号</div>
      </div>
      <div class="ttcontent">
        <el-table :data="cityUser" stripe style="width: 100%">
          <el-table-column label="序号" type="index"  align="center" :index="getIndex"> </el-table-column>
           <el-table-column align="center" label="用户名" prop="name">
          </el-table-column>
          <el-table-column align="center" label="账号" prop="username">
          </el-table-column>
          <!-- <el-table-column
            align="center"
            label="职称"
            min-width="180"
            prop="name"
          ></el-table-column>
          <el-table-column align="center" label="职务" prop="build_company">
          </el-table-column> -->
        </el-table>
      </div>
      <!-- 分页 -->
      <div class="pageTotal">
          <div class="block">
            <el-pagination
              :current-page="page"
              :page-count="totalPage"
              :page-size="limit"
              :total="totalCount"
              background
              layout=" prev, pager, next, total, jumper"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            >
            </el-pagination>
          </div>
        </div>
    </div>
  </div>
</template>
<script>
import { editSelfApi, userInfoApi, cityAccount } from "../../../../api/project";

export default {
  data() {
    return {
      //测试
      form: {},
      tableData: [],
      labelPosition: "right",
      formLabelAlign: {},

      page: 1,
      totalPage: 0,
      limit: 10,
      totalCount: 0,

      user: {},
      cityUser:{},
      edit_pwd: false,
      edit_contact: false,
      oldPassword: "",
      newPassword: "",

      showPass: false,
      showNewPass: false,
    };
  },
  mounted() {
    this.getCity();
    this.getUserInfo();
  },
  methods: {
      getIndex(index) {
      return (this.page - 1) * this.limit + index + 1;
    },
    // 地级市账号
    getCity() {
      let params = {
        token: this.$store.state.token,
        limit: this.limit,
        page: this.page,
      };
       let that=this;
      cityAccount(params).then((res) => {
        console.log("city", res.data);
        that.cityUser=res.data;
         that.page = res.page;
          that.totalCount = res.totalCount;
          that.totalPage = res.totalPage;
      });
    },
    //获得主账号信息
    getUserInfo() {
      let params = {
        token: this.$store.state.token,
      };
      let that = this;
      userInfoApi(params).then((res) => {
        console.log(res);
        that.user = res.data;
      });
    },

    //编辑信息
    editSelf() {
      let params = {
        token: this.$store.state.token,
        id: this.$store.state.user.id,
        name: this.user.name,
        telephone: this.user.telephone,
      };
      if (this.edit_pwd) {
        if (this.oldPassword != "") {
          params.oldpassword = this.oldPassword;
        } else {
          this.showPass = true;
          this.$message.warning("旧密码必填");
          return false;
        }
        if (this.newPassword != "") {
          params.password = this.newPassword;
        } else {
          this.showNewPass = true;
          this.$message.warning("新密码必填");
          return false;
        }
        this.edit_pwd = false;
      }
      let that = this;
      editSelfApi(params).then((res) => {
        console.log(res);
        that.$message.success("修改成功，请重新登录");
      });
    },
    // 分页
    handleSizeChange(page) {
      this.limit = page;
      this.getCity();
      //console.log(`每页 ${page} 条`)
    },
    handleCurrentChange(page) {
      //console.log(`当前页: ${page}`)
      this.page = page;
      this.getCity();
    },
  },
};
</script>
<style scoped>
/deep/.el-input {
  margin-right: 10px;
}
/deep/.el-form-item__content > span {
  font-size: 18px;
  font-weight: normal;
  color: #333333;
}
/deep/.el-form-item__label {
  font-size: 18px;
  font-weight: normal;
  color: #999999;
}
/deep/.el-upload-dragger {
  border: 1px dashed #3086fb;
}
/deep/.el-upload-dragger .el-icon-upload {
  margin: 18px 0 16px;
  color: #3086fb;
}
/deep/.el-upload-dragger .el-upload__text {
  color: #3086fb;
}
/deep/.el-upload-dragger {
  width: 261px;
  height: 140px;
}
/deep/.el-icon-edit {
  color: #3086fb;
  margin-left: 10px;
}
</style>
<style lang="scss" scoped>
.changePx {
  display: flex;
  flex-direction: row;
  .el-icon-check {
    margin-top: 10px;
  }
}
.topbasic {
  margin: 30px 0;
  padding: 20px 50px;
  height: 130px;
  background: #ffffff;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.08);
  .tbtitle {
    margin: 22px 50px;
    font-size: 18px;
    color: #333333;
  }
  .tbcont {
    display: flex;
    flex-direction: row;
    // justify-content: space-between;
    .account {
    }
  }
}
.testtable {
  margin-bottom: 20px;

  .tttotle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
    .ttleft {
      font-size: 18px;
      font-weight: normal;
      color: #333333;
      padding-left: 40px;
    }
  }
  .ttcontent {
    height: 52vh;
    background-color: #ffffff;
  }
  .pageTotal {
    margin-top: 37px;
    display: flex;
    justify-content: center;
  }
}
</style>
